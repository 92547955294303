
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Nav from './components/Nav';
import './index.css';
import CleanzAll from './components/CleanzAll';
import GreenzAll from './components/GreenzAll';
import Footer from './components/Footer';

function App() {
  return (
    <Router >
       <Nav />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/cleanz-all' element={<CleanzAll />} />
        <Route path='/greenz-all' element={<GreenzAll />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
