import React, { useEffect } from 'react'
import Landing from './Landing'
import WhyUs from './WhyUs'


const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  },[] )
  return (
    <>
    <Landing />
    <WhyUs />
    </>
  )
}

export default Home
