import React, { useEffect } from "react";
import weedImg from "../assets/weedcollage.png";

const CleanzAll = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[] )
  return (
    <>
    <div className="container">
      <div className="row">
        <div className="cleanzall__landing--container">
          <div className="cleanzall__landing">
            <h1>
              Cultivate <span className="blue">Healthier</span>
              <span className="blue"> Cannabis Plants</span>
            </h1>
            <p>
              We understand the challenges of growing high-quality cannabis.
              That's why we've harnessed the power of hypochlorous acid (HOCl)
              to provide an advanced, eco-friendly solution for your cultivation
              needs. Our cutting-edge treatments offer unparalleled benefits for
              your cannabis plants, ensuring a healthier, more vibrant crop.
            </p>
          </div>
          <img src={weedImg} alt="" className="cleanzall-weed__img" />
        </div>
        </div>
        </div>

      <div className="container cleanzall__why">
      <div className="row">
        <div className="cleanzall--why__container">
          <h1 className="cleanzall--why__title">
            Why <span className="blue">Cleanz All?</span>
          </h1>
          <div className="cleanzall--why__boxes">
            <div className="cleanzall--why__box">
              <h1>Pathogen Defense</h1>
              <p>
                Eliminates bacteria, fungi, and mold, safeguarding your plants
                from diseases that can harm their health and reduce yields.
              </p>
            </div>
            <div className="cleanzall--why__box">
              <h1>Safe & Non-Toxic</h1>
              <p>
                Non-toxic and safe for all marijuana plants, providing effective
                protection without causing any harm, perfect for regular use.
              </p>
            </div>
            <div className="cleanzall--why__box">
              <h1>Promotes Growth</h1>
              <p>
                Prevents infections and maintains a clean environment, helping
                your plants grow stronger and healthier for better yields.
              </p>
            </div>
            <div className="cleanzall--why__box">
              <h1>Eco-Friendly</h1>
              <p>
                Biodegradable and eco-friendly, a responsible choice for
                protecting your plants while preserving the environment.
              </p>
            </div>
            <div className="cleanzall--why__box">
              <h1>Versatile Application</h1>
              <p>
                Effective at every growth stage, protecting plants, soil, and
                equipment, ensuring comprehensive care from seedling to harvest.
              </p>
            </div>
            <div className="cleanzall--why__box">
              <h1>Trusted by Growers</h1>
              <p>
                A trusted solution among growers, relied upon industry-wide to
                keep plants healthy and cultivation processes smooth.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CleanzAll;
