import React from 'react'
import NavLogo from '../assets/logowhite.png'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate();
  return (
    <div className="container footer">
        <div className="row">
            <div className="footer__container">
                <div className="footer__logo">
                <img src={NavLogo} alt="" className="nav__logo--img" onClick={() => navigate('/')}/>
                </div>
                <div className="footer__links">
                    <ul>
                    <li>
                    <a onClick={() => navigate('/cleanz-all')}>Cleanz All</a>
                        </li>
                        <li>
                        <a onClick={() => navigate('/greenz-all')}>Greenz All</a>
                        </li>
                    </ul>
                </div>
                <div className="footer__copyright">
                    <h1>Good Grass © 2024 </h1>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
