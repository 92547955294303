import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import cleanseAllImg from '../assets/cleanz-all wth BG.jpg'
import greenzallImg from '../assets/GREENZ ALL LABEL.png'
import { useNavigate } from "react-router-dom";


const WhyUs = () => {

  const navigate = useNavigate();

    useEffect(() => {
        Aos.init();
      });

  return (
    <section id="whyus">
    <div className="container why">
      <div className="row">
        <div className="why__container">
          <div className="why__title">
            <h1  data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="500">
              Our<span className="blue"> Products</span>
            </h1>
          </div>
          <div className="why__items">
            <div className="why__item"  data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="800">
                <img src={cleanseAllImg} alt="" onClick={() => navigate('/cleanz-all')}/>
            </div>
            <div className="why__item" data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="800">
              <img src={greenzallImg} alt="" onClick={() => navigate('/greenz-all')}/>
            </div>
          </div>
          <h1 data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="900" className="click-img__title">Click on image to learn more.</h1>
        </div>
      </div>
    </div>
    </section>
  );
};

export default WhyUs;
