import React, { useEffect } from "react";
import weedImg from "../assets/greenscollage.png";

const GreenzAll = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="cleanzall__landing--container">
            <div className="cleanzall__landing">
              <h1>
                Boost your <span className="blue">Greens Health</span>
              </h1>
              <p>
                We recognize the demands of keeping your golf course in top
                condition. That's why we've utilized the power of hypochlorous
                acid (HOCl) to deliver a state-of-the-art, eco-friendly solution
                for turf management. Our innovative treatments provide unmatched
                benefits for your greens and fairways, ensuring a lush, healthy,
                and more resilient playing surface.
              </p>
            </div>
            <img src={weedImg} alt="" className="cleanzall-weed__img" />
          </div>
        </div>
      </div>

      <div className="container cleanzall__why">
        <div className="row">
          <div className="cleanzall--why__container">
            <h1 className="cleanzall--why__title">
              Why <span className="blue">Greenz All?</span>
            </h1>
            <div className="cleanzall--why__boxes">
              <div className="cleanzall--why__box">
                <h1>Pathogen Defense</h1>
                <p>
                  Eliminates bacteria, fungi, and mold, protecting your turf
                  from diseases that can harm its health and reduce the quality
                  of your greens.
                </p>
              </div>
              <div className="cleanzall--why__box">
                <h1>Safe & Non-Toxic</h1>
                <p>
                  Safe for all turf types, providing effective
                  protection without causing any harm, making it perfect for
                  regular use on golf courses.
                </p>
              </div>
              <div className="cleanzall--why__box">
                <h1>Promotes Growth</h1>
                <p>
                  Prevents infections and maintains a clean environment, helping
                  your grass grow stronger and healthier, leading to more
                  resilient greens.
                </p>
              </div>
              <div className="cleanzall--why__box">
                <h1>Eco-Friendly</h1>
                <p>
                  Biodegradable and eco-friendly, a responsible choice for
                  protecting your course while preserving the surrounding
                  environment.
                </p>
              </div>
              <div className="cleanzall--why__box">
                <h1>Versatile Application</h1>
                <p>
                  Effective across all areas, from fairways to greens, ensuring
                  comprehensive care for your turf throughout the entire golf
                  course.
                </p>
              </div>
              <div className="cleanzall--why__box">
                <h1>Groundskeeper <br />Trusted</h1>
                <p>
                  A trusted solution among golf course groundskeepers, relied
                  upon to keep turf healthy and maintain smooth, playable
                  surfaces.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GreenzAll;
