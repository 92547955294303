import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import NavLogo from "../assets/logo.png";
import LandingImg from "../assets/undraw_nature_m5ll.svg";

const Landing = () => {
  useEffect(() => {
    Aos.init();
  });

  return (
    <section id="landing">
      <div className="container landing">
        <div className="row">
          <div className="landing__container">
            <div className="landing__info">
              <h1 data-aos="fade-in" data-aos-duration="1500">
                Advanced Sanitization for{" "}
                <span className="blue">All Environments</span>
              </h1>

              <p
                data-aos="fade-up"
                data-aos-duration="1500"
                data-aos-delay="500"
              >
                We specialize in providing advanced sanitizing solutions for
                industries including cannabis cultivation and golf course
                maintenance. Our products eliminate pathogens and bacteria,
                ensuring safe and healthy environments.
              </p>
            </div>
            <div className="landing__img--container">
              <img
                src={LandingImg}
                alt=""
                className="landing__img"
                data-aos="fade-in"
                data-aos-duration="1500"
                data-aos-delay="1000"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Landing;
